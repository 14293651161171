import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgetPasswordComponent } from './account/forget-password/forget-password.component';
import { LoginComponent } from './account/login/login.component';
import { AuthGuard } from './core/guards/auth.guard';



const routes: Routes = [
  {
    path : 'login',
    component : LoginComponent
  },
  {
    path : 'forget-password',
    component : ForgetPasswordComponent
  },
  

  {
    path: '',
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
      canActivate: [AuthGuard],
  },
 

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
