import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError,BehaviorSubject } from 'rxjs';
import { catchError,finalize } from 'rxjs/operators';
import { LoginService } from 'src/app/shared/services/login/login.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({providedIn : 'root'})
export class HttpconfigInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
      null
    );
    constructor(private _service : LoginService,private _router : Router,private __ng: NgxUiLoaderService,) { }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.__ng.start();

        const token = sessionStorage.getItem('genie')

        if (token) {
            request = request.clone({
                setHeaders: {
                    'Authorization': `Bearer ${token}`,
                    'X-Client-Type': 'web'
                }
                // headers: request.headers.set('Authorization', 'Bearer ' + token),
            });
        }

        return next.handle(request).pipe(
            finalize(() =>this.__ng.stop() ),
            catchError((err) => {
                this.__ng.stop()
            if (err instanceof HttpErrorResponse && err.status === 500 && err.error.message == "jwt expired" || err.status === 401) {
               // this._toast.showConfirmation(err.error.message, 'error')
               this._router.navigate(['/login']);
               sessionStorage.clear();
            } else {
                let error = err.error.message || err.statusText;
                if (error == 'Unknown Error')
                    error = 'something went wrong ...!'
                //this._toast.showConfirmation(error, 'error')
            }
            let error = err.error.message || err.statusText;
            if (error == 'Unknown Error')
                error = 'something went wrong ...!'
            return throwError(error);
        }));
    }
}
