import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/shared/services/login/login.service';
import { NotificationsService, NotificationType } from 'angular2-notifications';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  heading : string = 'Forgot Password';
  subHeading : string = 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.';
  emailForm: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$')]),
   })
  passwordForm : FormGroup = new FormGroup({
    password: new FormControl(null, [Validators.required]),
    cnfPassword : new FormControl(null, [Validators.required])
   })

   showPasswordScreen : boolean = false;
   emailFormSubmitted : boolean = false;
   passwordFormSubmitted : boolean = false;
   verifyOtp : boolean = false;
   type : string;

  constructor(private router : Router,private _service : LoginService,private __notification:NotificationsService) { }

  ngOnInit() {
    // For future reference
  }

  returnToLogin(){
    this.router.navigate(['/login']);
  }

  submitEmail(){
    this.emailFormSubmitted = true;
    if(this.emailForm.valid){
      localStorage.setItem('creds',this.emailForm.value.email);
      this._service.forgetEmailPasswordApi(this.emailForm.value).subscribe((res:any)=>{
        if(res.status == 'OK'){
          this.verifyOtp = true;
          this.type = 'email';
          this.heading = 'OTP Verification';
          this.subHeading = 'We have sent an OTP code to your phone.';
          this.__notification.create('Success', res.message,
          NotificationType.Success, { timeOut: 2500, showProgressBar: true });
        }
 },
 err =>{
  this.__notification.error('Success',err,
  NotificationType.Error, { timeOut: 2500, showProgressBar: true });
 })
    }else{
      return;
    }
}

  submitPassword(){
    this.passwordFormSubmitted = true;
    if(this.passwordForm.valid){
      let creds = localStorage.getItem('creds');
      let obj = {
        "new_password":this.passwordForm.value.password,
        "email": creds
      }
         this._service.forgetPasswordApi(obj).subscribe((res:any)=>{
           if(res.status == 'OK'){
            this.__notification.create('Success', res.message,
            NotificationType.Success, { timeOut: 2500, showProgressBar: true });
            setTimeout(() => {
              this.router.navigate(['/login']);
              localStorage.removeItem('creds');
             }, 1000);
               
           }
         },
         err=>{
          this.__notification.error('Success',err,
          NotificationType.Error, { timeOut: 2500, showProgressBar: true });
         })
    }else{
      return;
    }
  }

  getVerification(event:any){
      if(event){
        this.showPasswordScreen = true;
        this.heading = 'Forgot Password';
        this.subHeading = 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.';
      }
  }

}
