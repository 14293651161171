export interface IMenuItem {
    icon?: string;
    selectedIcon?: string;
    label: string;
    to: string;
}

export let menuItems: IMenuItem[] = [
    {
        icon: 'assets/img/navbar/Path 177.svg',
        selectedIcon: 'assets/img/navbar/Path 177.svg',
        label: 'Home',
        to: `/home`,

    },
    {
        icon: 'assets/img/navbar/invoice.svg',
        selectedIcon: 'assets/img/navbar/selectedInvoice.svg',
        label: 'Invoices',
        to: `/invoice`,

    },
    {
        icon: 'assets/img/navbar/medical.svg',
        selectedIcon: 'assets/img/navbar/medical.svg',
        label: 'Medical',
        to: `/medical`,

    },
    // {
    //     icon: 'assets/img/navbar/medical.svg',
    //     selectedIcon: 'assets/img/navbar/medical.svg',
    //     label: 'News & Events',
    //     to: `/newsEvents`,
    // },
]
