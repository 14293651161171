import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './account/login/login.component';
import { ForgetPasswordComponent } from './account/forget-password/forget-password.component';
import { HeaderComponent } from './layout/header/header.component';
import { HttpconfigInterceptor } from './core/interceptor/httpconfig.interceptor';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ReactiveFormsModule } from '@angular/forms';
import { NgOtpInputModule } from  'ng-otp-input';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OtpComponent } from './core/components/otp/otp.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';
import { ModalModule} from 'ngx-bootstrap/modal';
import { MaxLengthDirective } from './core/components/otp-max-length';
import { NotificationsComponent } from '../app/pages/notifications/notifications.component'

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "blur": 0
};


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgetPasswordComponent,
    HeaderComponent,
    OtpComponent,
    MaxLengthDirective,
    NotificationsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TabsModule.forRoot(),
    SimpleNotificationsModule.forRoot(),
    ReactiveFormsModule,
    NgOtpInputModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig)
  ],
  providers: [ {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpconfigInterceptor,
    multi: true
  }],
  
  bootstrap: [AppComponent]
})
export class AppModule { }
