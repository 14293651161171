import { Directive, ElementRef, HostListener } from '@angular/core';
@Directive({
  selector: '[appMaxLength]'
})
export class MaxLengthDirective {
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    let temp = this.el.nativeElement.value
    //console.log('the maxlength directive',temp);
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    if(temp.length == 1){
        event.preventDefault();
    }
  }
}