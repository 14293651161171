import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private __http: HttpClient) { }

   // get User Profile
   getProfileDetails(): Observable<any> {
    return this.__http.get(`${environment.API}/user/v1/profile`);
  }

  //get department based on location
  getDepartmentById(id:number|string): Observable<any> {
    return this.__http.get(`${environment.API}/service_ticket/v1/ticketDepartment/${id}`);
  }

  //get f&b Tickets
  fandbByTickets(fromDate:string,toDate:string,page:any): Observable<any> {
    return this.__http.get(`${environment.API}/fb/v2/tickets?from_date=${fromDate}&to_date=${toDate}&page=${page}&limit=50`);
  }

  //Services Tickets
  servicesTickets(fromDate:string,toDate:string,page:any): Observable<any> {
    return this.__http.get(`${environment.API}/service_ticket/v2/tickets?from_date=${fromDate}&to_date=${toDate}&page=${page}&limit=50`);
  }

  getSubServicesByDepId(id:number){
    return this.__http.get(`${environment.API}/service_ticket/v2/first_service/${id}`);
  }

  secondDependsOnFirstService(depId:number,serviceId:number|string) : Observable<any> {
    return this.__http.get(`${environment.API}/service_ticket/v2/first_service/${depId}?depends_on=${serviceId}`);
  }

  addTicket(body: object): Observable<any> {
    return this.__http.post(`${environment.API}/service_ticket/v1/ticket`, body);
  }

   // forget Password
   resetPasswordApi(body: object): Observable<any> {
    return this.__http.post(`${environment.API}/auth/v1/resetPassword`, body);
  }
  
  getNotification(): Observable<any> {
    return this.__http.get(`${environment.API}/broadcast/v1/notifications`);

  }

  readNotification(body: any):Observable<any> {
    return this.__http.post(`${environment.API}/broadcast/v1/readNotification`, body);

  }

  submitDocuments(body: any):Observable<any> {
    return this.__http.post(`${environment.API}/medicalDocument/v1/createMedicalHistory`, body);
  }
  
}
