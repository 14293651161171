import { Component, OnInit,ViewChild,ElementRef, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators} from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { LoginService } from 'src/app/shared/services/login/login.service';
import { NotificationsService, NotificationType } from 'angular2-notifications';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {
  otpFormNumber: FormGroup = new FormGroup({
    boxOne: new FormControl('', [Validators.required,Validators.maxLength(1),Validators.pattern('^[0-9]*$')]),
    boxTwo: new FormControl('', [Validators.required,Validators.maxLength(1),Validators.pattern('^[0-9]*$')]),
    boxThree: new FormControl('', [Validators.required,Validators.maxLength(1),Validators.pattern('^[0-9]*$')]),
    boxFour: new FormControl('', [Validators.required,Validators.maxLength(1),Validators.pattern('^[0-9]*$')]),
   })

  @ViewChild("boxOne",{static : false}) boxOneElement: ElementRef;
  @ViewChild("boxTwo",{static : false}) boxTwoElement: ElementRef;
  @ViewChild("boxThree",{static : false}) boxThreeElement: ElementRef;
  @ViewChild("boxFour",{static : false}) boxFourElement: ElementRef;
  minute : number = 2;
  seconds : number = 30;
  timer : Subscription;
  secLength : number;
  otpFormSubmitted : boolean = false;
  otpLabel : string = 'Enter OTP';
  @Output() veification = new EventEmitter<any>();
  @Input() type : string;
  verifyType : string = '';

  constructor(private _service : LoginService, private __notification:NotificationsService) { }

  ngOnChanges(changes : SimpleChanges){
    console.log('the changes',changes);
     if(changes.type.currentValue){
          this.verifyType = changes.type.currentValue;
     }
  }

  ngOnInit() {

    this.otpFormNumber.controls.boxOne.valueChanges.subscribe((res:any) => {
      console.log('the box1',res);
      if( res  || res == 0){
       this.boxOneElement.nativeElement.style.borderColor = 'green'
       this.boxTwoElement.nativeElement.focus();
      }else if(res == '' && res.toString().length == 0){
          this.otpFormSubmitted = true;
    }
 })

 this.otpFormNumber.controls.boxTwo.valueChanges.subscribe((res:any) => {
   console.log('the box2',res);
   if( res  || res == 0){
     this.boxTwoElement.nativeElement.style.borderColor = 'green'
    this.boxThreeElement.nativeElement.focus();
   }else if(res == '' && res.toString().length == 0){
    this.boxOneElement.nativeElement.focus();
     this.otpFormSubmitted = true;
     this.otpLabel = 'Re-enter OTP';
  }
    
  })

  this.otpFormNumber.controls.boxThree.valueChanges.subscribe((res:any) => {
    console.log('the box3',res);
   if( res  || res == 0){
     this.boxThreeElement.nativeElement.style.borderColor = 'green'
    this.boxFourElement.nativeElement.focus();
   }else if(res == '' && res.toString().length == 0){
      this.boxTwoElement.nativeElement.focus();
      this.otpFormSubmitted = true;
      this.otpLabel = 'Re-enter OTP';
   }
    
  })

  this.otpFormNumber.controls.boxFour.valueChanges.subscribe((res:any) => {
    
   console.log('the box4',res);
   if(res == '' && res.toString().length == 0){
    this.boxThreeElement.nativeElement.focus();
    this.otpFormSubmitted = true;
    this.otpLabel = 'Re-enter OTP';
   }else if(res && res.toString().length == 1){
     this.boxFourElement.nativeElement.style.borderColor = 'green'
     this.otpLabel = 'Enter OTP';
}
    
  })

 
 

  this.timer = interval(1000).subscribe(x => this.getTimeDiffrence());
  }

  getTimeDiffrence(){
    this.secLength = this.seconds.toString().length;
      if(this.seconds > 0){
        this.seconds = this.seconds-1;
        this.secLength = this.seconds.toString().length;
      }else if(this.seconds == 0){
       if(this.minute > 0 ){
          this.seconds = 59;
          this.secLength = this.seconds.toString().length;
          this.minute = this.minute - 1;
         }
      }
  }

  ngOnDestroy() {
    this.timer? this.timer.unsubscribe() : '';
 }

 resendOtp(){
  let cred = localStorage.getItem('creds');
  if(this.verifyType == 'mobile'){
   let obj = {
      'phone_number' : cred
    }
  this._service.loginPhoneApi(obj).subscribe((res:any)=>{
    if(res.status == 'OK'){
      this.minute = 2;
      this.seconds = 30;
      this.secLength = this.seconds.toString().length;
      this.__notification.create('Success', res.message,
      NotificationType.Success, { timeOut: 2500, showProgressBar: true });
    }
   },
   err=>{
    this.__notification.create('Error', err,
    NotificationType.Error, { timeOut: 2500, showProgressBar: true });
   })
  }else{
    let obj = {
      'email' : cred
    }
    this._service.forgetEmailPasswordApi(obj).subscribe((res:any)=>{
     if(res.status == 'OK'){
      this.minute = 2;
      this.seconds = 30;
      this.secLength = this.seconds.toString().length;
      this.__notification.create('Success', res.message,
      NotificationType.Success, { timeOut: 2500, showProgressBar: true });
    }
    },
    err =>{
      this.__notification.create('Error', err,
    NotificationType.Error, { timeOut: 2500, showProgressBar: true });
    })
  }
}

  verifyOTP(){
   
    this.otpFormSubmitted = true;
    if(this.otpFormNumber.valid){
      let obj;
      console.log('the otp',this.otpFormNumber.value);
      let otp = +(''+this.otpFormNumber.value.boxOne+this.otpFormNumber.value.boxTwo+this.otpFormNumber.value.boxThree+this.otpFormNumber.value.boxFour);
      let creds = localStorage.getItem('creds');
      console.log('the verify OTP',this.verifyType);
      if(this.verifyType == 'mobile'){
        obj = {
          "OTP":otp,
          "isMobile":true,
          "phone_number":creds,
          "isLogin":true
        } 
      }else{
        obj = {
          "OTP":otp,
          "isEmail":true,
          "email":creds,
        }
      }
     
      console.log('the otp',obj);
      this._service.verifyOtp(obj).subscribe((res:any)=>{
         if(res.status == 'OK'){
          this.veification.emit(res);
          this.otpLabel = 'Enter OTP';
          this.__notification.create('Success','OTP Verified Successfully',
          NotificationType.Success, { timeOut: 2500, showProgressBar: true });
      }
      },
      err=>{
        this.otpLabel = 'Re-enter OTP';
        this.otpFormNumber.controls.boxOne.setValue('');
        this.otpFormNumber.controls.boxTwo.setValue('');
        this.otpFormNumber.controls.boxThree.setValue('');
        this.otpFormNumber.controls.boxFour.setValue('');
        this.boxOneElement.nativeElement.focus();
        this.__notification.create('Error', err,
        NotificationType.Error, { timeOut: 2500, showProgressBar: true });
      })
      
  }else{
        this.otpLabel = 'Re-enter OTP';
    }
  }
  getkeyUpevent(event:any,type:string){
   console.log('the input focus',this.otpFormNumber.controls.boxFour.value);
   
   if(event.key == 'Backspace'){
    
    switch (type) {
      case 'two':
        this.otpFormNumber.controls.boxTwo.setValue(null);
        if(!this.otpFormNumber.controls.boxTwo.value){
        this.otpFormNumber.controls.boxOne.setValue(null);
        this.otpFormSubmitted = true;
        this.boxOneElement.nativeElement.focus();
        }
        break;
        case 'three':
          this.otpFormNumber.controls.boxThree.setValue(null);
          if(!this.otpFormNumber.controls.boxThree.value){
            this.otpFormNumber.controls.boxTwo.setValue(null);
            this.otpFormSubmitted = true;
            this.boxTwoElement.nativeElement.focus();
          }
          
        break;
        case 'four':
            this.otpFormNumber.controls.boxFour.setValue(null);
            if(!this.otpFormNumber.controls.boxFour.value){
              this.otpFormNumber.controls.boxThree.setValue(null);
              this.otpFormSubmitted = true;
              this.boxThreeElement.nativeElement.focus();
            }
            
        break;
    }
    
    }
}


}
