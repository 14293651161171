import { Component, OnInit} from '@angular/core';
import { NavigationStart,Router } from '@angular/router';
import { HomeService } from 'src/app/shared/services/home/home.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  currentUrl : string ;
  profileDetails : any;
  notificationData:any = [];
  showMore :any= false;
  broadCastId:any = [];
  unreadCount: string = '';
  data:any = [];
  latestBroadcastMapId: any[] = []; 
  isRead:any = []
  pushApiCalled:boolean = false;
  showIcon: boolean = false;
  hasUnread:boolean = false;
  oldNotifications: any[] = [];
  newNotifications: any[] = []; 
  constructor(
    private router : Router,private _service :HomeService) {
    this.showMore = new Array(this.notificationData.length).fill(false);

    this.router.events.subscribe((event:any)=>{
      if (event instanceof NavigationStart) {
       this.getCurrentURLNotification(event.url);
      }
    })
    this.getCurrentURLNotification(this.router.url);
   }

  ngOnInit() {
    this.getData()
  }
  ToggleNavBar () {
    let element: HTMLElement = document.getElementsByClassName( 'navbar-toggler' )[ 0 ] as HTMLElement;
    if ( element.getAttribute( 'aria-expanded' ) == 'true' ) {
        element.click();
    }
}
 
  getCurrentURLNotification(urlNotification : string){
    let tempNotification = urlNotification.split('/');
    if(tempNotification.length == 3){
     let pathNotification = tempNotification.slice(0, tempNotification.length - 1).join('/');
     this.currentUrl = pathNotification;
   }else if(tempNotification.length == 4){
    let pathUrlNotification = tempNotification.slice(0, tempNotification.length - 2).join('/');
    this.currentUrl = pathUrlNotification;
    }else{
    this.currentUrl = urlNotification;
     }
  }
    
getTimeDifference(createdAt: string): string {
  const currentDate = new Date();
  const oneDay = 24 * 60 * 60 * 1000;
  const createdDate = new Date(createdAt.slice(0, -5) + "Z");
  const timeDiff = Math.abs(currentDate.getTime() - createdDate.getTime());
  const minutes = Math.floor((timeDiff / 1000 / 60) % 60);
  const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
  const days = Math.floor(timeDiff / oneDay);
  if (days > 0) {
    return `${days}d${days > 1 ? '' : ''}`;
  } else if (hours > 0) {
   return `${hours}h${hours > 1 ? '' : ''}`;
  } else if (minutes > 0 || timeDiff <= 60000) {
      return timeDiff <= 60000 ? 'just now' : `${minutes}m${minutes > 1 ? '' : ''}`;
    } else {
      return '';
    }
  }

getData() {
  this._service.getNotification().subscribe((res:any)=>{
    this.notificationData = res.data;
    let newNotifications: any[] = []; 
    for (let i = this.notificationData.length - 1; i >= 0; i--) {
      const notification = this.notificationData[i];
      const unreadBroadcasts = notification.broadcast_user_maps.filter((element:any) => element.is_read === false);
      if (!notification.isRead && !this.notificationExists(notification, this.oldNotifications)) {
        newNotifications.push(notification);
      }
      if (unreadBroadcasts.length > 0) {
        notification.isLatestUnread = true;
      }
    }
    this.oldNotifications = this.oldNotifications.concat(newNotifications);
    this.notificationData.forEach((item:any) => {
      item.timeDifference = this.getTimeDifference(item.createdAt);
      item.broadcast_user_maps.forEach((element:any) => {
        this.broadCastId.push({ "broadcast_user_map_id": element.broadcast_user_map_id });
        this.isRead.push({ "is_read": element.is_read });
      });
    });
    const hasUnread = this.isRead.some((obj:any) => obj.is_read === false);
    const unreadBroadcasts = this.notificationData.flatMap(item => item.broadcast_user_maps.filter((element:any) => element.is_read === false));
    const badge = document.querySelector('.badge') as HTMLElement;
    if (hasUnread && unreadBroadcasts.length > 0) {
      badge.innerText = unreadBroadcasts.length.toString();
      badge.style.display = 'inline-block';
    } else {
      badge.style.display = 'none';
    }  
    this.pushApiCalled = !hasUnread;
  });
}

readNotification() {
  this.getData();
  if (!this.pushApiCalled) {
    const unreadBroadcasts = this.notificationData.flatMap((item:any) => item.broadcast_user_maps.filter((element:any) => element.is_read === false));
    if (unreadBroadcasts.length > 0) {
      const broadcastIds = unreadBroadcasts.map((broadcast:any) => ({ "broadcast_user_map_id": broadcast.broadcast_user_map_id }));      
      this._service.readNotification(broadcastIds).subscribe((res:any)=>{
        this.pushApiCalled = true;
        const badge = document.querySelector('.badge') as HTMLElement;
        badge.style.display = 'none';
        this.oldNotifications.forEach((notification:any) => {
          if (!notification.isRead) {
            notification.isRead = true;
          }
        });
      });
    } 
  } 
}

notificationExists(notification: any, array: any[]): boolean {
  for (let i = 0; i < array.length; i++) {
    if (array[i].id === notification.id) {
      return true;
    }
  }  
  return false;
}

toggleIconVisibility() {
  this.showIcon = !this.showIcon;
}

}
