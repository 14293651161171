import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
 urls : any = ['/login','/forget-password'];
 showHeaders : boolean = false;

 constructor(private router : Router){}

 ngOnInit(){
    this.router.events.subscribe((event:any)=>{
      
       if(event instanceof NavigationEnd){
           let index = this.urls.indexOf(event.url);
           if(index == -1){
            this.showHeaders= true;
           }else{
            this.showHeaders= false;
           }
       }
    })
 }

}
