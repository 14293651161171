import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor( private __http: HttpClient) { }

  // Phone Login
  loginPhoneApi(body: object): Observable<any> {
    return this.__http.post(`${environment.API}/auth/v1/otpLogin`, body);
  }

  //Verify OTP Login
  verifyOtp(body: object): Observable<any> {
    return this.__http.post(`${environment.API}/auth/v1/verifyOTP`, body);
  }

  // Phone Login
  loginEmailApi(body: object): Observable<any> {
    return this.__http.post(`${environment.API}/auth/v1/login`, body);
  }

  // Submit email to get OTP
  forgetEmailPasswordApi(body: object): Observable<any> {
    return this.__http.post(`${environment.API}/auth/v1/emailOTP`, body);
  }

  // forget Password
  forgetPasswordApi(body: object): Observable<any> {
    return this.__http.post(`${environment.API}/auth/v1/forgotPassword`, body);
  }

  // Refresh Token
  refreshToken(body:object): Observable<any>{
    return this.__http.post(`${environment.API}/account/refreshToken`,body)
  }
}
