import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/shared/services/login/login.service';
import { NotificationsService, NotificationType } from 'angular2-notifications';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  heading : string = 'Sign-in to bring the Genie to life!';
  subHeading : string = 'Sign-in to bring the Genie to life!';
  loginFormNumber: FormGroup = new FormGroup({
    phone_number: new FormControl(null, [Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{6,13}$")]),
   })
   loginFormEmail : FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$')]),
    password: new FormControl(null, [Validators.required]),
   })
   emailFormSubmitted : boolean = false;
   mobileFormSubmitted : boolean = false;
   otpVerify : boolean = false;
   type : string;
   constructor(private router : Router,
    private _service : LoginService,
    private __notification:NotificationsService) { }

  ngOnInit() {
    if(sessionStorage.getItem('genie')) {
      this.router.navigate(['home'])
    }
  }

 getTabDetails(event:any){
  
   if(event.target.innerHTML == 'Via OTP'){
         this.otpVerify = false;
         this.loginFormNumber.reset();
         this.mobileFormSubmitted = false;
         this.heading  = 'Sign-in to bring the Genie to life!';
         this.subHeading = 'Sign-in to bring the Genie to life!';
      }else if(event.target.innerHTML == 'Via Email'){
        this.heading  = 'Sign-in to bring the Genie to life!';
        this.subHeading = 'Sign-in to bring the Genie to life!';
        this.loginFormEmail.reset();
        this.emailFormSubmitted = false;
      }
  }

  moveToOTP(){
    this.mobileFormSubmitted = true;
   if(this.loginFormNumber.valid){
    localStorage.setItem('creds',this.loginFormNumber.value.phone_number);
    this._service.loginPhoneApi(this.loginFormNumber.value).subscribe((res:any)=>{
           if(res.status == 'OK'){
            this.otpVerify = true;
            this.type = 'mobile';
            this.heading = 'OTP Verification';
            this.subHeading = 'We have sent an OTP code to your phone.';
            this.__notification.create('Success', res.message,
            NotificationType.Success, { timeOut: 2500, showProgressBar: true });
          }
    },
    err=>{
      console.log('the error',err);
      this.__notification.create('Error', err,
        NotificationType.Error, { timeOut: 2500, showProgressBar: true });
    })
   }else{
    return;
   }
    
  }
  saveEmailPassowrd(){
      this.emailFormSubmitted = true;
      if(this.loginFormEmail.valid){
          this._service.loginEmailApi(this.loginFormEmail.value).subscribe((res:any)=>{
            if(res.status == 'OK'){
              this.__notification.create('Success', res.message,
              NotificationType.Success, { timeOut: 2500, showProgressBar: true });
              sessionStorage.setItem('genie', res.data.token);
              sessionStorage.setItem('refreshGenie', res.data.refreshToken);
              sessionStorage.setItem('user', JSON.stringify(res.data.user));
              this.router.navigate(['/home']);  
             
            }
          },
          err=>{
            this.__notification.create('Error', err,
            NotificationType.Error, { timeOut: 2500, showProgressBar: true });
          })
      }else{
        return;
      }
  }

  getVerification(event:any){
      console.log('the response from otp',event);
      if(event){
        sessionStorage.setItem('genie', event.data.token);
          sessionStorage.setItem('refreshGenie', event.data.refreshToken);
          sessionStorage.setItem('user', JSON.stringify(event.data.user));
        this.router.navigate(['/home']);
      }
     
  }

  forgetPassword(){
   this.router.navigate(['/forget-password']);
  }

}
