import { Component, OnInit, TemplateRef} from '@angular/core';
import { NavigationStart,Router } from '@angular/router';
import { menuItems,IMenuItem } from 'src/app/shared/constants/menu';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HomeService } from 'src/app/shared/services/home/home.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  menuItems : IMenuItem[] = menuItems;
  currentUrl : string ;
  profileDetails : any;
  bsModalRef: BsModalRef;

  constructor(
    private router : Router,private _service :HomeService, private __modalService: BsModalService ) {

    this.router.events.subscribe((event:any)=>{
      if (event instanceof NavigationStart) {
       this.getCurrentURLHeader(event.url);
      }
    })
    this.getCurrentURLHeader(this.router.url);
   }

  ngOnInit() {
    this.getProfileDetails();
  }
  ToggleNavBar () {
    let element: HTMLElement = document.getElementsByClassName( 'navbar-toggler' )[ 0 ] as HTMLElement;
    if ( element.getAttribute( 'aria-expanded' ) == 'true' ) {
        element.click();
    }
}
  getProfileDetails(){
    this._service.getProfileDetails().subscribe((res:any)=>{
        this.profileDetails = res.data;
    })
  }

  getCurrentURLHeader(headerUrl : string){
    let tempHeader = headerUrl.split('/');
    if(tempHeader.length == 3){
     let pathHeader = tempHeader.slice(0, tempHeader.length - 1).join('/');
     this.currentUrl = pathHeader;
   }else if(tempHeader.length == 4){
    let pathUrl = tempHeader.slice(0, tempHeader.length - 2).join('/');
    this.currentUrl = pathUrl;
    }else{
    this.currentUrl = headerUrl;
     }
  }
    getSelectedMenue(url:string){
    
     this.getCurrentURLHeader(url);
    //  coolapse navbar clicl nav item link
     let element: HTMLElement = document.getElementsByClassName( 'navbar-toggler' )[ 0 ] as HTMLElement;
    if ( element.getAttribute( 'aria-expanded' ) == 'true' ) {
        element.click();
    }
  }
  /**
   *  showCollapse
   */
  showCollapse(){
    //  coolapse navbar clicl nav item link
    let element: HTMLElement = document.getElementsByClassName( 'navbar-toggler' )[ 0 ] as HTMLElement;
    if ( element.getAttribute( 'aria-expanded' ) == 'true' ) {
        element.click();
    } 
  }
  profile(){
    this.router.navigate(['/home/profile']);
  }

  emailModal(template: TemplateRef<any>){
   
    this.bsModalRef = this.__modalService.show(template,{ backdrop: 'static'});
  }

  logout(template: TemplateRef<any>){
    this.bsModalRef = this.__modalService.show(template,{ backdrop: 'static'});
   
  }
/** 
 * logoutYes
*/
  logoutYes(){
    this.router.navigate(['/login']);
    sessionStorage.clear();
    localStorage.clear();
    this.bsModalRef.hide()
  }
/** 
 * logoutNo
*/
  logoutNo(){
    this.bsModalRef.hide()
  }

}
